.css-typing p {
    color: #fff;
    line-height: 40px;
    margin-inline-end: 5px;
    margin: 2px;
    width: fit-content;
    border-right: .15em solid orange;
    font-family: "Menlo-Regular", sans-serif;
    font-size: 35px;
    white-space: nowrap;
    overflow: hidden;
    bottom: 1%;
    padding-top: -100px;
    margin-top: -100px;
}

.css-typing p:nth-child(1) {
    margin-top: -50px;
    border-right: .3em solid orange;
    opacity: 0;
    width: 0.5em;
    -webkit-animation: type3 2s steps(13, end), blink .5s step-end infinite alternate;
    animation: type3 2s steps(13, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-duration: 750ms;
}



@keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: transparent;
    }
}

.top-tags {
    bottom: auto;
    top: 15px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 14px;
    font-family: 'Menlo-Regular', sans-serif;
}

.text-zone {
    position: initial;
    left: 10%;
    transform: translateY(-50);
    width: 20%;
    max-height: 90%;
    color: #fff;
    font-family: 'Menlo-Regular', sans-serif;
}
.page-content {
    margin-top: -50px;
    position: relative;
    left: 10%;
    top: -100px;
    color: darkorange;
    transform: translateY(-50);
    width: 20%;
    max-height: 90%;
    font-family: 'Menlo-Regular', sans-serif;
    animation: fadeIn 2s 2.75s backwards;
}

.stage-cube-cont {
    width: 50%;
    height: 80%;
    top: -75px;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left:calc(50% - 50px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
    }

    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
    }

    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }

    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }

    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #ec4d28;
    }
}

@keyframes spincube {
    from, to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    33% {
        transform: rotateY(-90deg) rotateX(90deg);
    }

    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
        transform: rotateX(90deg);
    }
}
