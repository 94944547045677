.socials-icons a {
    color: white;
    font-size: 2.75rem;
    bottom: auto;
    margin-right: 5px;
    border-radius: 75%;
    border: 2px solid white;
    padding: 15px 15px 15px 15px;

    &:hover {
        background: #fff;
        color: #333;
    }
}

.text-zone {
    position: absolute;
    left: 10%;
    top: 35%;
    transform: translateY(-50);
    width: 40%;
    max-height: 90%;
    color: #fff;
    font-family: 'Menlo-Regular', sans-serif;

    p {
        width: 400px;
        animation: fadeIn 1s 2.5s backwards;
        color: darkorange;
    }
}

.single-col {
    
    max-width: 750px;
    margin: 125px;
    border-spacing: 50px;
    margin-top: 350px;


}

.top-tags {
    bottom: auto;
    top: 15px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 14px;
    font-family: 'Menlo-Regular', sans-serif;
    height: fit-content;
}
.css-typing p {
    color: #fff;
    line-height: 40px;
    margin-inline-end: 5px;
    margin: 2px;
    border-right: .15em solid orange;
    font-family: "Menlo-Regular", sans-serif;
    font-size: 35px;
    white-space: nowrap;
    overflow: hidden;
    top: 1%;
    bottom: 1%;
}

.css-typing p:nth-child(1) {
    border-right: .15em solid orange;
    width: 6.25em;
    opacity: 0;
    -webkit-animation: type3 5s steps(13, end), blink .5s step-end infinite alternate;
    animation: type3 5s steps(13, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-duration: 850ms;
}



@keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: transparent;
    }
}

.terminal-entry {
    bottom: auto;
    top: 35px;
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    left: 120px;
    font-size: 14px;
    font-family: 'Menlo-Regular', sans-serif;
}


.page-content {
    position: absolute;
    left: 10%;
    top: 45%;
    color: darkorange;
    transform: translateY(-50);
    width: 40%;
    max-height: 90%;
    font-family: 'Menlo-Regular', sans-serif;
}


.map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 150%;
    margin-top: -50%;
}

.leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}

.info-map {
    position: absolute;
    background: #000;
    top: 300px;
    right: 25%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
    height: fit-content;

    span {
        font-size: 16px;

        span {
            color: #ffd700;
        }
    }
}

@media screen and (max-width: 1200px) {
    .map-wrap {
        float: none;
        margin: 0;
        width: 100%;
        height: 400px;
    }

    .info-map {
        display: none;
    }
}