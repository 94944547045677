.portfolio-page::-webkit-scrollbar {
    display: none;
}

.portfolio-page {
    .top-tags {
        bottom: auto;
        top: 15px;
    }

    height: 100%;
    overflow-y: scroll;


    .tags {
        color: #ffd700;
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        left: 120px;
        font-size: 14px;
        font-family: 'Menlo-Regular', sans-serif;
        height: fit-content;
    }

    .experience {
        animation: fadeIn 2s 4s backwards;
        color: darkorange;

        p {
            color: #fff;
            font-family: "Menlo-Regular", sans-serif;
            margin-bottom: 45px;
            width: 80%;
        }

        h3 {
            font-size: larger;
        }
    }

    .projects {
        position: fixed;
        margin-left: 200px;
        animation: fadeIn 2s 4s backwards;
        color: darkorange;

        p {
            color: #fff;
            font-family: "Menlo-Regular", sans-serif;
            margin-bottom: 45px;
            width: 80%;
        }

        h3 {
            font-size: larger;
        }
    }

    .css-typing p {
        color: #fff;
        line-height: 30px;
        margin-inline-end: 5px;
        margin: 2px;
        border-right: 10px solid orange;
        font-family: "Menlo-Regular", sans-serif;
        font-size: 25px;
        white-space: nowrap;
        overflow: hidden;
        top: 1%;
        bottom: 1%;
    }

    .css-typing2 p:nth-child(1) {
        border-right: .15em solid orange;
        width: 6.5em;
        opacity: 0;
        -webkit-animation: type2 2s steps(11, end), blink .5s step-end 850ms alternate;
        animation: type2 2s steps(11, end), blink .5s step-end 850ms alternate;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        animation-duration: 850ms;
    }

    .css-typing2 p {
        color: #fff;
        line-height: 30px;
        margin-inline-end: 5px;
        margin: 2px;
        font-family: "Menlo-Regular", sans-serif;
        font-size: 25px;
        white-space: nowrap;
        overflow: hidden;
        top: 1%;
        bottom: 1%;
    }

    .css-typing2 p:nth-child(1) {
        width: 6.75em;
        margin-top: 1em;
        opacity: 0;
        -webkit-animation: type3 2s steps(11, end), blink .5s step-end 850ms alternate;
        animation: type3 2s steps(11, end), blink .5s step-end 850ms alternate;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        animation-duration: 750ms;
    }

    .css-typing3 p {
        position: absolute;
        margin-left: 500px;
        color: #fff;
        line-height: 30px;
        margin-inline-end: 5px;
        margin: 2px;
        border-right: .15em solid orange;
        font-family: "Menlo-Regular", sans-serif;
        font-size: 25px;
        white-space: nowrap;
        overflow: hidden;
        bottom: 1%;
    }

    .css-typing3 p:nth-child(1) {
        position: initial;
        margin-left: 90%;
        margin-top: -400px;
        border-right: .15em solid orange;
        width: 5.5em;
        margin-top: 1em;
        opacity: 0;
        -webkit-animation: type3 2s steps(9, end), blink .5s step-end infinite alternate;
        animation: type3 2s steps(9, end), blink .5s step-end infinite alternate;
        -webkit-animation-delay: 3s;
        animation-delay: 5s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        animation-duration: 750ms;
    }

    @keyframes type2 {
        0% {
            width: 0;
        }

        1% {
            opacity: 1;
        }

        100% {
            opacity: 1;
            border: none;
        }
    }

    @-webkit-keyframes type2 {
        0% {
            width: 0;
        }

        1% {
            opacity: 1;
        }


        100% {
            opacity: 1;
            border: none;
        }
    }

    @keyframes type3 {
        0% {
            width: 0;
        }

        1% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes type3 {
        0% {
            width: 0;
        }

        1% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes blink {
        50% {
            border-color: transparent;
        }
    }

    @-webkit-keyframes blink {
        50% {
            border-color: transparent;
        }
    }

    .text-zone {
        position: absolute;
        left: 10%;
        top: 15%;
        transform: translateY(75);
        width: 50%;
        max-height: 100%;
    }

    .titles {
        display: grid;
        grid-template-columns: 50% 50%; /* Creates two equally sized columns */
        grid-gap: 10px;
    }

    .bodies {
        display: grid;
        grid-template-columns: 100% 90%; /* Creates two equally sized columns */
        grid-gap: 10px;

        .paper-trading-platform {
            animation: fadeIn 2s 6s backwards;
            margin-top: 20px;
            margin-left: -40px;
            width: 80%;

            img {
                width: 100%;
                height: auto;
                border: 5px solid black;
            }

            .subtitle {
                display: flex;
                align-items: center;

                h2 {
                    animation: none;
                    color: white;
                    font-size: 18px;
                    font-family: 'Menlo-Regular', sans-serif;
                }

                a {
                    margin-left: 55%;
                }

                .github {
                    margin-top: 0px;
                    width: 25px;
                    width: 25px;
                    height: auto;
                }
            }

            h3 {
                color: darkorange;
            }

            p {
                color: #ffd700;
            }
        }
    }
}
